/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10/12/2021.
 */
import React, { useState } from 'react'
import { authStore } from '../../store/auth/authStore'
import { LOGIN_FORGOT_PASSWORD } from '../../constants'
import { Link, useNavigate } from 'react-router-dom'

export const ForgotPasswordSubmit = () => {
  const navigate = useNavigate()
  const forgotPasswordSubmit = authStore((state) => state.forgotPasswordSubmit)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')

  const [error, setError] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    if (!email || !code || !password) {
      alert('please fill in all fíelds')

      return ''
    }

    forgotPasswordSubmit(
      email,
      code,
      password // the new password
    )
      .then((result) => {
        // at this time the result is logged in if no MFA required
        if (result === 'SUCCESS') {
          //setNavigateTo(LOGIN.to)
          navigate('/', { replace: true })
        }
        console.log(' ForgotPasswordSubmit > result = ', result)

        setError(result.toString())
      })
      .catch((e) => {
        console.log(' ForgotPasswordSubmit > e = ', e)
        setError(e.message)
      })
  }

  /*if (navigateTo !== '') {
		return (
			<Redirect
				to={{
					pathname: navigateTo,
					//state: { user: userResponse },
				}}
			></Redirect>
		)
	}*/

  return (
    <div>
      <h3 className="font-weight-bold text-center">Enter a new password and code</h3>
      <form className="login-page" onSubmit={handleFormSubmit} style={{ width: 360 }}>
        <div className="w-100 ">
          <label htmlFor="code">Code from email or text</label>
          <input
            id="code"
            type="text"
            placeholder="Code from email or text"
            autoComplete="code"
            value={code}
            name="code"
            onChange={(e) => setCode(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div className="w-100 ">
          <label htmlFor="username">your email</label>
          <input
            id="email"
            type="text"
            placeholder="Email"
            autoComplete="email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            id="new-password"
            type="password"
            autoComplete="new-password"
            placeholder="Repeat password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
          <div className=" ">
            <input
              style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
              type="submit"
              value="Login"
            />

            <p className="mt-1 text-center text-danger">{error}</p>

            <div className="d-flex justify-content-center ">
              <Link to={LOGIN_FORGOT_PASSWORD.to}>Request new code</Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

import { BASE_URL } from '../constants'

/**
 *
 * The convention of having the getProjects_endpoint as a string,
 * is to be able to use them as keys when querying with SWR
 *
 */
export const getFailedParticipantsByProjectId = (projectId) => {
  return BASE_URL + `/dashboard/projectById/` + projectId + '/failed'
}

export const getProjectByIdEndpoint = (projectId) => {
  return BASE_URL + `/dashboard/projectById/` + projectId
}

export const getAssignmentsByProjectIdEndpoint = (projectId) => {
  return BASE_URL + `/dashboard/assignmentByProjectId/` + projectId
}

export const getTranslateEndpoint = () => {
  return BASE_URL + `/dashboard/translate`
}

import { getReq } from '../service/API'
import useSWR from 'swr'
import { getFailedParticipantsByProjectId } from '../service/api-gateway'
//import { errorStore } from '../store/ErrorStore'

export const useFailedParticipants = (projectId: string) => {
  const p = getFailedParticipantsByProjectId(projectId)

  //const cacheKey = formId ? p : null
  const { data, error } = useSWR(p, getReq, { suspense: true })

  /*if (error) {
		setError(error.error)
	}*/

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}

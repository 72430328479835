/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import React from 'react'
import Btn from '../../components/button/Btn'
import { useProjects } from '../../hooks/useProjects'
import { Link } from 'react-router-dom'
import { PROJECT_PAGE } from '../../constants'

export const FrontPage = () => {
  const { data } = useProjects()

  console.log(' FrontPage > data = ', data)

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 mt-4">
          <p className="fw-bold">Active Projects</p>
        </div>
      </div>
      <div className="row" style={{}}>
        {data.map((item, index) => {
          return (
            <div key={index} className="col-3 mt-4">
              <div
                className="bg-white item-shadow p-4 d-flex flex-column justify-content-between"
                style={{ minHeight: 250 }}
              >
                <div className="">
                  <h5 className="fw-bold mb-0">{item.title}</h5>
                  <p className="p-small">{item.condition}</p>
                </div>

                <Link to={PROJECT_PAGE.gotoPage(item._id)} className="mt-2">
                  <Btn label="View"></Btn>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/12/2022.
 */
import { Button, Collapse, Drawer } from 'antd'
import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFailedParticipants } from '../../hooks/useFailedParticipants'
import { post } from '../../service/API'
import { getTranslateEndpoint } from '../../service/api-gateway'

const { Panel } = Collapse
type Props = { projectId: string; visible: boolean; setVisible: (value: boolean) => void }

export const FailedParticipantsDrawer = ({ projectId, visible, setVisible }: Props) => {
  const navigate = useNavigate()

  const [translateState, setTranslateState] = useState<any>({})
  const { data } = useFailedParticipants(projectId)

  useLayoutEffect(() => {
    setVisible(true)
  }, [setVisible])

  const onClose = () => {
    setVisible(false)
  }

  const layoutChange = (e) => {
    if (!e) navigate('/project/' + projectId)
  }

  const translateStr = (value, index, subIndex) => {
    const obj = {
      text: value,
      language: 'dk',
    }

    return post(getTranslateEndpoint(), obj)
      .then((result) => {
        console.log('FailedParticipantsDrawer > result = ', result)

        const translateValue = result?.data?.TranslatedText
        const copy = { ...translateState }
        copy[index] = copy[index] ? copy[index] : {}
        copy[index][subIndex] = translateValue
        setTranslateState(copy)

        return result
      })
      .catch((error) => {
        console.log('FailedParticipantsDrawer > error = ', error)
        alert('The translation did not work')
        return Promise.reject(error)
      })
  }

  return (
    <Drawer
      afterVisibleChange={layoutChange}
      title="User details"
      destroyOnClose={true}
      width={'50%'}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <div className="container-fluid px-0">
        <div className="row" style={{}}>
          <Collapse ghost className="px-0" expandIconPosition={'end'}>
            {data.map((item, index) => {
              const lang = item.language || 'en'

              return (
                <Panel header={item.displayName} key={index}>
                  <div className="col-12 mb-2 p-4" style={{ background: '#f6f6f6' }}>
                    <h6 className="mb-3 fw-bold">{item.displayName}</h6>
                    <p className="p-small mb-3">
                      This shows a list of the options selected by the user. The list only include the question which
                      was marked as "excluded".
                    </p>
                    {item.questionAnswer.map((qa, subIndex) => {
                      const question = qa.question
                      const answer = qa.answer

                      if (question.type === 'bmi') {
                        //console.log(' FailedParticipantsDrawer > question = ', answer)
                      }

                      const bmiResult = question.type === 'bmi' ? JSON.parse(answer.value) : null

                      // if questuiob does not have options like a cmi question
                      const options = question.options || []
                      const qTitle = question.title[lang]
                      const answerValue = parseInt(answer.value)

                      let translateValue = translateState[index] ? translateState[index][subIndex] : null

                      return (
                        <div key={subIndex} className="row border py-3 mb-2 bg-white">
                          <p className="p-small opacity-50 text-capitalize">{question.type}</p>
                          <div className="col-12 ">
                            <div className="d-flex justify-content-between">
                              <div className="">
                                <p className="fw-bold">{qTitle}</p>
                              </div>

                              {translateValue || lang === 'en' ? null : (
                                <Button
                                  type="default"
                                  onClick={async () => {
                                    translateStr(qTitle, index, subIndex)
                                  }}
                                >
                                  <p className="p-small">Translate</p>
                                </Button>
                              )}
                            </div>
                            {translateValue ? (
                              <p className="fw-bold p-3 mt-2 " style={{ background: '#ececec' }}>
                                {translateValue}
                              </p>
                            ) : null}
                            <hr className="my-2 opacity-25" />
                            {question.type === 'number' && <p>{answerValue}</p>}
                            {question.type === 'bmi' && (
                              <div className="d-flex">
                                <p className="me-2">height:{Math.round(bmiResult.height)}</p>
                                <p className="me-2">weight:{Math.round(bmiResult.weight)}</p>
                                <p className="me-2">bmi:{bmiResult.bmi}</p>
                              </div>
                            )}
                            {options.map((option, opIdx) => {
                              const label = option[lang]
                              const selected = answerValue === opIdx

                              return (
                                <div
                                  key={opIdx}
                                  className="d-flex align-items-center"
                                  style={{ opacity: selected ? 1 : 0.4 }}
                                >
                                  {selected ? (
                                    <>
                                      <p className="fw-bold">{label}</p>
                                      <p className="ms-2 opacity-50 p-small">- selected by user</p>
                                    </>
                                  ) : (
                                    <p className="">{label}</p>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Panel>
              )
            })}
          </Collapse>
        </div>
      </div>
    </Drawer>
  )
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  //aws_cognito_identity_pool_id: 'eu-central-1:e72267df-0569-4761-be12-0a4767f891f5',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_uHFQ94ZeC',
  aws_user_pools_web_client_id: '4qmk1v5gsf79obqhia50tgchml',
  /*
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],*/
}

export default awsmobile

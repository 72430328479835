/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/08/2022.
 */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { PageType } from '../constants'

type Props = { pages: PageType[]; projectId: string }

export const Navigation = ({ pages, projectId }: Props) => {
  const activeStyle = { borderBottom: '2px solid white' }
  return (
    <div className="w-100 bg-primary d-flex justify-content-center position-relative">
      <div className="d-flex">
        {pages.map((page, index) => {
          return (
            <NavLink
              key={index}
              style={({ isActive }: { isActive: boolean }) => {
                console.log(' ProjectPage > isActive = ', isActive)

                return isActive ? activeStyle : {}
                //return isActive ? activeStyle : 'undefined'
              }}
              className="text-white text-decoration-none px-3"
              to={page.gotoPage(projectId)}
            >
              <p className="p-small py-2">{page.title.toUpperCase()}</p>
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/08/2022.
 */
import React, { useState } from 'react'
import { formatDistanceStrict, formatDistanceToNow } from 'date-fns'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { Button, Checkbox, Popconfirm } from 'antd'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { post } from '../../service/API'
import { BASE_URL, COLOR_PRIMARY, SUB_PAGES } from '../../constants'
import { MoveEnrollment } from './MoveEnrollment'
import { Navigation } from '../../components/Navigation'
import { useAssignmentsByProjectId } from '../../hooks/useAssignmentsByProjectId'
import { Stats, STATS_TYPE_TEXT } from './Stats'
import { ReactComponent as InfoIcon } from '../../assets/info.svg'

export const AssignmentPage = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data, mutate } = useAssignmentsByProjectId(projectId)

  console.log('AssignmentPage > data = ', data)

  const showImageEvaluation = data.showImageEvaluation
  console.log('AssignmentPage > showImageEvaluation = ', showImageEvaluation)

  const confirmEnrollments = (arrayOfEnrollmentIds) => {
    const p = BASE_URL + '/dashboard/subject/confirmById'
    return post(p, { enrollmentArr: arrayOfEnrollmentIds })
      .then((result) => {
        console.log('top result = ', result)
        mutate()
        return result
      })
      .catch((error) => {
        console.log('error = ', error)
        alert(error)
        return Promise.reject(error)
      })
  }

  return (
    <>
      <Routes>
        <Route path={'/move/:enrollmentId'} element={<MoveEnrollment></MoveEnrollment>} />
      </Routes>
      <Navigation pages={SUB_PAGES} projectId={projectId}></Navigation>

      <div className="container-fluid bg-white mb-4">
        <div className="container">
          <div className="row" style={{}}>
            <div className="col-12">
              <h3 className="fw-bold mt-4 mb-0">{data.project.title}</h3>
              <p className="fw-bold opacity-75 mb-4">{data.project.projectRef}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {data.sites
          .sort((a, b) => {
            return a.site.active === true ? -1 : 0
          })
          .map((siteData, siteIndex) => {
            console.log(' AssignmentPage > siteDate.active = ', siteData.site.active)
            return (
              <SiteListItem
                key={siteIndex}
                siteData={siteData}
                showImageEvaluation={showImageEvaluation}
                confirmEnrollments={confirmEnrollments}
              ></SiteListItem>
            )
          })}
      </div>
    </>
  )
}

const SiteListItem = ({ siteData, confirmEnrollments, showImageEvaluation }) => {
  const [checkAll, setCheckAll] = useState(false)
  const [selectedSubjects, setSelectedSubjects] = useState({})

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked

    if (checked === false) {
      setSelectedSubjects({})
    } else {
      const copy = { ...selectedSubjects }
      siteData.enrollments.forEach((item) => {
        copy[item._id] = checked
      })

      setSelectedSubjects(copy)
    }
    // update button state
    setCheckAll(checked)
  }

  const onCheckClick = (id: string) => {
    const copy = { ...selectedSubjects }
    const subject = copy[id] ? false : true
    if (!subject) {
      delete copy[id]
    } else {
      copy[id] = subject
    }

    setSelectedSubjects(copy)
  }

  const confirmSingle = (id: string) => {
    return confirmEnrollments([id])
      .then((result) => {
        console.log(' AssignmentPage > sinfle = ', result)
        return result
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  const confirmSelected = () => {
    const arrayOfIds = Object.keys(selectedSubjects).filter((key) => {
      const value = selectedSubjects[key]
      return value ? key : false
    })

    return confirmEnrollments(arrayOfIds).then((result) => {
      setSelectedSubjects({})
      return result
    })
  }

  //const confirmSelected

  return (
    <div className="row mb-3  " style={{}}>
      <div className="col-12 bg-white pb-4 pt-4 d-flex justify-content-between">
        <div className="me-3 ">
          <div className="d-flex">
            <h3 className="ms-2 mb-0 fw-bold text-primary">{siteData.site.internalName}</h3>
          </div>

          <div className="d-flex mt-2">
            {siteData.site.active ? (
              <div className="ms-2 d-inline-block">
                <div className="d-flex text-white rounded-pill " style={{ background: 'green' }}>
                  <p className="p-small mx-2">Active </p>
                </div>
              </div>
            ) : null}
            <p className="ms-2 mb-0 p-small opacity-50">{siteData.site._id}</p>
          </div>
        </div>
        <div className="d-flex "></div>
      </div>

      <div className="col-12 bg-white py-2">
        <div className="ms-2 mb-2 d-flex justify-content-between">
          <div className="d-flex">
            <Stats label="Enrollments" data={siteData.enrollments.length}></Stats>
            <Stats label="Site capacity" data={siteData.site.capacity || 0}></Stats>
            <Stats
              labelStyle={{ color: siteData.newReferalCount >= siteData.site.capacity ? 'red' : COLOR_PRIMARY }}
              label="Waiting at site"
              data={siteData.newReferalCount}
            ></Stats>
          </div>
          <div className="d-flex">
            <Stats
              type={STATS_TYPE_TEXT}
              label="Since a subject was moved"
              data={
                siteData.site.lastMoveAt
                  ? formatDistanceStrict(new Date(siteData.site.lastMoveAt), new Date(), { addSuffix: true })
                  : '-'
              }
            ></Stats>
            <Stats
              label="Since subject status update"
              type={STATS_TYPE_TEXT}
              data={
                siteData.site.lastStatusAt
                  ? formatDistanceStrict(new Date(siteData.site.lastStatusAt), new Date(), { addSuffix: true })
                  : '-'
              }
            ></Stats>
          </div>
        </div>
      </div>

      <div
        className="col-12 bg-white pb-2 pt-3 mb-2"
        style={{ borderTop: '1px solid #d3d3d3', borderBottom: '2px solid #d3d3d3' }}
      >
        <div className="ms-3 d-flex justify-content-between">
          <div className="mt-1">
            <Checkbox indeterminate={false} onChange={onCheckAllChange} checked={checkAll}>
              <p className="ms-4 muted">Select all</p>
            </Checkbox>
          </div>
          <Popconfirm
            disabled={Object.keys(selectedSubjects).length === 0}
            title="Are you sure ?"
            onConfirm={confirmSelected}
            onVisibleChange={() => console.log('visible change')}
          >
            <Button
              type="primary"
              className="mb-2"
              style={{ width: 163 }}
              disabled={Object.keys(selectedSubjects).length === 0}
            >
              Confirm selected
            </Button>
          </Popconfirm>
        </div>
      </div>

      {siteData.enrollments.map((item, index) => {
        const isSelected = selectedSubjects[item._id] === true ? true : false

        const itemEvaluation = item.evaluation

        return (
          <div
            key={item._id}
            className="col-12 bg-white mb-2 py-3"
            style={{ borderBottom: '1px solid ', borderColor: '#d3d3d3' }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="mx-3">
                  <Checkbox
                    onChange={() => {
                      onCheckClick(item._id)
                    }}
                    checked={isSelected}
                  ></Checkbox>
                </div>
                <div className="ms-3 me-5">
                  <p className="p-small text-primary ">{item._id}</p>
                  <p className="mb-0 text-capitalize ">{item.displayName}</p>
                </div>
                <div className="ms-5">
                  <p className="p-small text-primary ">Completed screening:</p>
                  <p className="">{formatDistanceToNow(new Date(item.finishedPrescreeningAt), {})} ago</p>
                </div>
                <div className="ms-5">
                  <p className="p-small text-primary ">Completed in:</p>
                  <p className="">
                    {formatDistanceStrict(
                      new Date(item.finishedPrescreeningAt),
                      new Date(item.startedPrescreeningAt),
                      {}
                    )}
                  </p>
                </div>

                {/*<p className="opacity-50 ms-2 p-small">{item.updatedAt}</p>*/}
                {/*<p className="opacity-50 ms-2">{formatDistanceToNow(new Date(), new Date(item.state.followUpBy), {})}</p>*/}
              </div>
              <div className="">
                <Link to={'move/' + item._id}>
                  <Button disabled={isSelected} className="me-2">
                    Move
                  </Button>
                </Link>
                <Popconfirm
                  title="Are you sure ?"
                  onConfirm={() => {
                    return confirmSingle(item._id)
                  }}
                  onVisibleChange={() => console.log('visible change')}
                >
                  <Button type="primary">Confirm</Button>
                </Popconfirm>
              </div>
            </div>

            {showImageEvaluation ? (
              <div className="mt-3 border-top">
                <Evaluation evaluation={itemEvaluation}></Evaluation>
              </div>
            ) : null}
          </div>
        )
      })}

      <div className="col-12 my-5 ">{/*SPACER*/}</div>
    </div>
  )
}

/*type EvaluationType = {
  answerId: string
  enrollmentId: string
  status: string
  comment?: string
  createdBy: string
  createdByEmail: string
  declined: string
  score: number
}*/

const Evaluation = ({ evaluation }) => {
  console.log('AssignmentPage > evaluation = ', evaluation)
  if (!evaluation) {
    return (
      <div className="mt-2 d-flex">
        <div className="ms-3 mt-2">
          <InfoIcon className="me-2  " style={{ marginTop: 6, fill: '#e7bb1b' }}></InfoIcon>
        </div>
        <div className="ms-4 ps-2">
          <p className="p-small text-primary ">Status:</p>
          <p className="">Pending image evaluation</p>
        </div>
      </div>
    )
  }

  if (evaluation?.status === 'IMAGE_NOT_ACCEPTED') {
    return (
      <div className="mt-2 d-flex">
        <div className="ms-3 mt-2">
          <InfoIcon style={{ fill: 'tomato' }}></InfoIcon>
        </div>

        <div className="ms-4 ps-2" style={{ width: 240 }}>
          <p className="p-small text-primary ">Evaluation:</p>
          <p className="">{evaluation.status}</p>
        </div>

        <div className="ms-4 ps-2">
          <p className="p-small text-primary ">Evaluation:</p>
          <p className="">{evaluation.declined}</p>
        </div>

        <div className="ms-4 ps-2">
          <p className="p-small text-primary ">Evaluated by:</p>
          <p className="">{evaluation.createdByEmail}</p>
        </div>
      </div>
    )
  }

  if (evaluation?.status === 'IMAGE_ACCEPTED') {
    return (
      <div className="d-flex mt-3">
        <div className="ms-3 mt-2">
          <InfoIcon style={{ fill: 'green' }}></InfoIcon>
        </div>

        <div className="ms-4 ps-2">
          <p className="p-small text-primary  ">Scored:</p>
          <p className="bg-primary py-1 text-white rounded-2 text-center fw-bold p-small ">{evaluation.score}</p>
        </div>

        <div className="ms-4 ps-2">
          <p className="p-small text-primary ">Evaluation:</p>
          <p className="">{evaluation.status}</p>
        </div>

        <div className="ms-4 ps-2">
          <p className="p-small text-primary ">Evaluator:</p>
          <p className="">{evaluation.createdByEmail}</p>
        </div>

        {/*<p className="p-small">Image Score: {evaluation.score}</p>*/}
      </div>
    )
  }

  return <div className="mt-2 d-inline-block">Some went wrong</div>
}

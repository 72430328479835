/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/11/2022.
 */
import React from 'react'
import { COLOR_PRIMARY } from '../../constants'

type Props = { label: string; data: string | number; className?: string; type?: 'TEXT' | 'NUMBER'; labelStyle?: any }

export const STATS_TYPE_NUMBER = 'NUMBER'
export const STATS_TYPE_TEXT = 'TEXT'

export const Stats = ({
  label,
  data,
  className = 'd-flex flex-column me-2 border ps-3 pe-3 py-2 justify-content-center',
  labelStyle = null,
  type = 'NUMBER',
}: Props) => {
  return type === STATS_TYPE_NUMBER ? (
    <div className={className} style={{ background: '#fafafa' }}>
      <h3 className="text-center mb-0 mt-0  fw-bold " style={labelStyle ? labelStyle : { color: COLOR_PRIMARY }}>
        {data}
      </h3>
      <p className=" mb-0 mt-0 p-small text-muted">{label}</p>
    </div>
  ) : (
    <div className={className} style={{ background: '#fafafa' }}>
      <h5 className="mt-1 mb-1 text-center mb-0 mt-0 text-primary fw-bold ">{data}</h5>
      <p
        className=" mb-0 mt-0 p-small text-muted text-center align-self-center"
        style={{ maxWidth: 100, lineHeight: 1.2 }}
      >
        {label}
      </p>
    </div>
  )
}

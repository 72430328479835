/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10/12/2021.
 */
import React, { useState } from 'react'
import { authStore } from '../../store/auth/authStore'
import { Auth } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom'

export const LoginMFA = () => {
  const user: any = authStore((state) => state.user)
  const init: any = authStore((state) => state.init)
  const navigate = useNavigate()
  const [mfaCode, setMfaCode] = useState('')

  const [error, setError] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    if (!user || !user.challengeName) {
      alert('The MFA flow was not started correctly')
      return
    }
    console.log(' NewPasswordPage > user = ', user)
    console.log(' NewPasswordPage > mfaCode = ', mfaCode)

    Auth.confirmSignIn(
      user, // Return object from Auth.signIn()
      mfaCode, // Confirmation code
      user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .then((result) => {
        console.log('mfa result = ', result)
        init().then((result) => {
          navigate('/', { replace: true })
        })
      })
      .catch((error) => {
        console.log('error = ', error)
        setError(error)
      })
  }

  return (
    <div className="bg-white p-4 shadow" style={{ maxWidth: 375 }}>
      <div className="mb-3">
        <h4 className="font-weight-bold text-center">Enter code </h4>
        <p className="text-center">You should have recieved a email or text message with a code</p>
      </div>

      <form className="login-page" onSubmit={handleFormSubmit}>
        <div className="w-100 ">
          <label htmlFor="username">MFA code</label>

          <input
            id="mfa-code"
            type="text"
            placeholder="Insert code "
            value={mfaCode}
            name="mfa-code"
            onChange={(e) => setMfaCode(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div className=" ">
          <input
            style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
            type="submit"
            value="Send"
          />
          <p className="mt-1 text-center text-danger">{error.toString()}</p>
          <div className="d-flex justify-content-center">
            <Link to="/login">
              <p className="p-small">Back to login</p>
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}

import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'

export const useBasicStats = () => {
  const p = BASE_URL + `/users/test`

  const { data, error } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? data : data,
    isLoading: !error && !data,
    isError: error,
  }
}

import React from 'react'

import LoginForm from './LoginForm'

import { Link, Route, Routes } from 'react-router-dom'
import {
  LOGIN_FORGOT_PASSWORD,
  LOGIN_FORGOT_PASSWORD_SUBMIT,
  LOGIN_MFA_CODE,
  LOGIN_NEW_PASSWORD,
} from '../../constants'
import { ForgotPasswordSubmit } from './ForgotPasswordSubmit'
import { LoginMFA } from './LoginMFA'
import { ForgotPassword } from './ForgotPassword'
import { CompleteNewPassword } from './CompleteNewPassword'

export const LoginPage = () => {
  return (
    <div className="container h-100 ">
      <div className="row h-100" style={{}}>
        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
          <Routes>
            <Route path="/" element={<LoginForm></LoginForm>}></Route>

            <Route
              path={'signup'}
              element={
                <Link className="mt-2" to="/">
                  <p>Sign up</p>
                </Link>
              }
            ></Route>

            {/*            <Route path={LOGIN_NEW_PASSWORD.to}>
              <ForgotPasswordSubmit></ForgotPasswordSubmit>
            </Route>*/}

            <Route path={LOGIN_MFA_CODE.path} element={<LoginMFA />} />
            <Route path={LOGIN_FORGOT_PASSWORD.path} element={<ForgotPassword />} />
            <Route path={LOGIN_FORGOT_PASSWORD_SUBMIT.path} element={<ForgotPasswordSubmit />} />
            <Route path={LOGIN_NEW_PASSWORD.path} element={<CompleteNewPassword />} />
          </Routes>
          {/*<TestBtn></TestBtn>*/}
        </div>
      </div>
    </div>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 03-08-2020.
 */
import React from 'react'

import './spinner.scss'

const Spinner = ({ size = 6, style = {} }) => {
  //const { error } = props
  //const statusTxt = !error ? 'Getting data...' : error

  return <div className="loader text-primary" style={{ fontSize: size, ...style }}></div>
}

export default Spinner

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/10/2022.
 */
import React from 'react'
import { Navigation } from '../../components/Navigation'
import { SUB_PAGES } from '../../constants'
import { useParams } from 'react-router-dom'
import { useBasicStats } from '../../hooks/useBasicStats'
import ReactApexChart from 'react-apexcharts'

let staticData = {
  series: [
    {
      name: 'box',
      type: 'boxPlot',
      data: [
        /* {
					x: new Date('2017-01-01').getTime(),
					y: [54, 66, 69, 75, 88],
				},
				{
					x: new Date('2018-01-01').getTime(),
					y: [43, 65, 69, 76, 81],
				},
				{
					x: new Date('2019-01-01').getTime(),
					y: [31, 39, 45, 51, 59],
				},
				{
					x: new Date('2020-01-01').getTime(),
					y: [39, 46, 55, 65, 71],
				},
				{
					x: new Date('2021-01-01').getTime(),
					y: [29, 31, 35, 39, 44],
				},*/
      ],
    },
    /* {
			name: 'outliers',
			type: 'scatter',
			data: [
				{
					x: new Date('2017-01-01').getTime(),
					y: 32,
				},
				{
					x: new Date('2018-01-01').getTime(),
					y: 25,
				},
				{
					x: new Date('2019-01-01').getTime(),
					y: 64,
				},
				{
					x: new Date('2020-01-01').getTime(),
					y: 27,
				},
				{
					x: new Date('2020-01-01').getTime(),
					y: 78,
				},
				{
					x: new Date('2021-01-01').getTime(),
					y: 15,
				},
			],
		},*/
  ],
  options: {
    chart: {
      type: 'boxPlot',
      height: 350,
    },
    colors: ['#008FFB', '#FEB019'],
    title: {
      text: 'BoxPlot - Scatter Chart',
      align: 'left',
    },
    xaxis: {
      type: 'category',
      /*tooltip: {
				formatter: function (val) {
					return new Date(val).getFullYear()
				},
			},*/
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
  },
}

export const StatsPage = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data } = useBasicStats()

  const parsed = Object.keys(data.data).map((item) => {
    console.log(' StatsPage > item = ', item)
    const arr = data.data[item]

    const yArr = arr.map((sub) => {
      return sub[1]
    })
    //console.log(' StatsPage > obj = ', obj)
    return { x: item, y: yArr }
  })

  const newseries = { ...staticData.series }
  newseries[0].data = parsed as any
  //const newData = { ...staticData, series: newseries }
  const newData = { ...staticData, ...newseries }
  //newData.series[0].data = parsed

  //staticData.series.data = parsed
  //console.log(' StatsPage > parsed = ', parsed)

  return (
    <>
      <Navigation pages={SUB_PAGES} projectId={projectId}></Navigation>
      <div className="container-fluid">
        <div className="row" style={{}}>
          <div className="col-12">
            <ReactApexChart options={newData.options as any} series={newData.series} type="boxPlot" height={470} />
          </div>
        </div>
      </div>
    </>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'
import { authStore } from '../../store/auth/authStore'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useProjects } from '../../hooks/useProjects'
import { PROJECT_PAGE } from '../../constants'

export const Header = () => {
  return (
    <div className="container-fluid bg-white" style={{ boxShadow: '0px -5px 10px 7px rgba(0, 0, 0, 0.05)' }}>
      <div className="row py-3" style={{}}>
        <div className="col-12 col-md-3 pb-0 d-flex justify-content-center justify-content-md-start align-items-center">
          <Link to="/" className="mb-0 text-decoration-none">
            <h5 className="mb-0 fw-bold">Dashboard</h5>
          </Link>
        </div>

        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">

          <Suspense fallback={'...'}>
            <ProjectMenu></ProjectMenu>
          </Suspense>
        </div>

        <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
          <SettingsMenu></SettingsMenu>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 bg-primary" style={{ height: 2 }}></div>
      </div>
    </div>
  )
}

const ProjectMenu = () => {
  const { data } = useProjects()

  const items = data.map((item, index) => {
    return (
      <Menu.Item key={index}>
        <Link className="text-decoration-none" to={PROJECT_PAGE.gotoPage(item._id)}>
          <h6 className="my-2 " style={{ minWidth: 200 }}>
            {item.title}
          </h6>
        </Link>
      </Menu.Item>
    )
  })

  const menu = <Menu>{items}</Menu>

  return (
    <Dropdown overlay={menu} placement="top" arrow>
      <div className="d-flex pointer">
        <p className="" style={{ fontSize: '.95rem' }}>
          Projects
        </p>
        <div className="ms-2  " style={{ marginTop: -2 }}>
          <DownOutlined />
        </div>
      </div>
    </Dropdown>
  )
}

const SettingsMenu = () => {
  const user = authStore((state) => state.user)

  const name = user?.attributes.email || ''
  const letter = user?.attributes.email ? user?.attributes.email[0] : ''

  const logout = authStore((state) => state.logout)

  const loguserOut = () => {
    logout()
  }

  const menu = (
    <Menu>
      <Menu.Item key={0} disabled={true}>
        <p style={{ minWidth: 100 }}>{name}</p>
      </Menu.Item>
      <Menu.Item key={1}>
        <p onClick={loguserOut} style={{ width: 100 }}>
          Logout
        </p>
      </Menu.Item>
    </Menu>
  )

  const size = 34
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <div className="d-flex pointer mr-2">
        <div className="rounded-circle bg-black me-2" style={{ width: size, height: size }}>
          <p className="d-flex justify-content-center align-items-center h-100 fw-bold text-white">
            {letter.toUpperCase()}
          </p>
        </div>
        <div className="mt-1 ">
          <DownOutlined />
        </div>
      </div>
    </Dropdown>
  )
}

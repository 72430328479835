/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Header } from './components/header/Header'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { FrontPage } from './pages/frontpage/FrontPage'
import { PROJECT_PAGE, STATS_PAGE } from './constants'
import { ProjectPage } from './pages/project/ProjectPage'
import { AssignmentPage } from './pages/assignment/AssignmentPage'
import { StatsPage } from './pages/stats/StatsPage'

export const Main = () => {
  return (
    <>
      <Header></Header>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <FrontPage></FrontPage>
            </Suspense>
          }
        />

        <Route
          path={PROJECT_PAGE.to}
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <ProjectPage></ProjectPage>
            </Suspense>
          }
        />

        <Route
          path={STATS_PAGE.to}
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <StatsPage></StatsPage>
            </Suspense>
          }
        />

        <Route
          path={`/assignment/:projectId/*`}
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <AssignmentPage></AssignmentPage>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}

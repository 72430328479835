/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14/07/2022.
 */

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useProjectById } from '../../hooks/useProjectById'
import { Navigation } from '../../components/Navigation'
import { groupBy } from 'lodash'

import { SUB_PAGES } from '../../constants'
import { SiteMap } from '../../components/map/SiteMap'
import { EventType } from '../../Types'
import { FailedParticipantsDrawer } from './FailedParticipantsDrawer'

type ProjectType = {
  active: boolean
  projectRef: string
  title: string
  condition: string
  //age: AgeRange
  maxDistance: number
  maxDistanceToTravel: number
  sites: SiteType[]
  //questionnaires: Types.ObjectId[]
  languages: string[] //LanguageISOCode[]
  retention: any[] //Retention[]
}

type SiteType = {
  _id: string
  projectId: string
  internalName: string
  officialName: string
  position: { lng: number; lat: number }
  loc: { type: string; coordinates: number[] }
  enrollments
  state: string
  country: string
  address: string
  zipcode: string
  active: boolean
  reach: number
}

const statekeys = [
  'ASSIGNED_TO_SITE',
  'NEW_REFERRAL',
  'SCHEDULED_FOR_SCREENING',
  'SCHEDULED_FOR_RANDOMISATION',
  'SUBJECT_RANDOMISED',
  'SUBJECT_DISQUALIFIED_MEDICAL',
  'SUBJECT_DISQUALIFIED_MOTIVATIONAL',
  'SUBJECT_DISQUALIFIED_MIXED',
]

export const ProjectPage = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data } = useProjectById(projectId)
  const project: ProjectType = data.project

  const [showFailed, setShowFailed] = useState<boolean>(false)

  const maxDistance = project.maxDistance / 1000
  const maxDistanceToTravel = project.maxDistanceToTravel / 1000

  const getProjectMarkers = () => {
    const markers = project.sites.map((site) => {
      return {
        lat: site.position.lat,
        lng: site.position.lng,
        active: site.active,
      }
    })
    return markers
  }

  //console.log(' ProjectPage > data = ', data)

  const failedPrescreening = data.signedUp.filter((item) => {
    return item.stateType === EventType.SubjectNotEligeble
  })

  const willingToTravel = data.signedUp.filter((item) => {
    return item.reach === 'WITHIN_TRAVEL' && item.willTravel === true
  })

  const declinedToTravel = data.signedUp.filter((item) => {
    return item.reach === 'WITHIN_TRAVEL' && item.willTravel === false
  })

  const outsideReach = data.signedUp.filter((item) => {
    return item.reach === 'OUTSIDE'
  })

  const showFailedClick = () => {
    setShowFailed(true)
  }

  return (
    <>
      {/*<Routes>
        <Route
          path="/users/create"
          element={
            <ProjectUserDrawer projectId={projectId} visible={showDrawer} setVisible={setShowDrawer}>
              <UserCreate closeDrawer={closeDrawer}></UserCreate>
            </ProjectUserDrawer>
          }
        />
      </Routes>*/}

      {showFailed && (
        <FailedParticipantsDrawer
          projectId={projectId}
          visible={showFailed}
          setVisible={setShowFailed}
        ></FailedParticipantsDrawer>
      )}
      <Navigation pages={SUB_PAGES} projectId={projectId}></Navigation>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row" style={{}}>
            <div className="col-12">
              <h3 className="fw-bold mt-4">{project.title}</h3>
              <p className="fw-bold opacity-75">{project.projectRef}</p>
            </div>
          </div>
          <div className="row mt-4" style={{}}>
            <div className="col-3 mb-4">
              <div className=" item-shadow px-3 py-4">
                <h3 className="fw-bold mb-0 "> {data.signedUp.length}</h3>
                <p className="p-small">Total signups</p>
              </div>
            </div>
            <div className="col-3 mb-4 pointer" onClick={showFailedClick}>
              <div className=" item-shadow px-3 py-4">
                <h3 className="fw-bold mb-0 ">{failedPrescreening.length}</h3>
                <p className="p-small text-decoration-underline">Failed prescreening</p>
              </div>
            </div>
            <div className="col-2 mb-4">
              <div className="item-shadow px-3 py-4  ">
                <h3 className="fw-bold mb-0 ">{willingToTravel.length}</h3>
                <p className="p-small">Willing to travel</p>
              </div>
            </div>

            <div className="col-2">
              <div className="item-shadow px-3 py-4  ">
                <h3 className="fw-bold mb-0 ">{declinedToTravel.length}</h3>
                <p className="p-small opacity-50">Declined travel </p>
              </div>
            </div>
            <div className="col-2">
              <div className="item-shadow px-3 py-4  ">
                <h3 className="fw-bold mb-0 ">{outsideReach.length}</h3>
                <p className="p-small opacity-50">Out of bounds </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row" style={{}}>
          <SiteMap
            width={'100%'}
            height={'400px'}
            markers={getProjectMarkers()}
            maxDistance={maxDistance}
            maxDistanceTravel={maxDistanceToTravel}
          ></SiteMap>

          {project.sites.map((site: SiteType, index) => {
            const groups = groupBy(site.enrollments, 'stateType')

            return (
              <div key={index} className="col-4 mb-3">
                <div className="bg-white p-4 item-shadow">
                  <div className="">
                    <h5 className="fw-bold mb-0">{site.internalName}</h5>
                    <p className="p-small opacity-50 mb-3">{site._id}</p>

                    {statekeys.map((key, keyIndex) => {
                      const group = groups[key] || []
                      const label = key.toLowerCase().replaceAll('_', ' ')
                      const count = group.length
                      return (
                        <p key={keyIndex} className="p-small text-capitalize">
                          {label}: {count}
                        </p>
                      )
                    })}
                    <hr className="my-2" />

                    <p className="p-small ">Total assigned: {site.enrollments.length} </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

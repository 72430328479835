/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/12/2021.
 */
import React, { useRef } from 'react'
import { gsap } from 'gsap'
import { COLOR_PRIMARY } from '../../constants'

type Props = {
  background?: string
  label?: string
  width?: string | number
  style?: any
  textColor?: string
  onClick?: (e: any) => void
  outline?: boolean
  disabled?: boolean
  height?: number
}

const Btn = ({
  background = COLOR_PRIMARY,
  label = 'btn label',
  width = '100%',
  style = {},
  onClick,
  textColor = '#FFFFFF',
  outline = false,
  disabled = false,
  height = 40,
}: Props) => {
  const textRefOne: any = useRef()
  const textRefTwo: any = useRef()
  const bgRef: any = useRef()
  const aniOffset = height / 4

  const mouseOver = () => {
    if (disabled) {
      return
    }

    gsap.killTweensOf(bgRef.current)
    gsap.killTweensOf(textRefOne.current)
    gsap.killTweensOf(textRefTwo.current)
    gsap.to(bgRef.current, 0.5, { scaleY: 1.08, scaleX: 1.05, ease: 'Expo.easeOut' })
    gsap.to(textRefOne.current, 0.4, { y: -aniOffset, alpha: 0, ease: 'Expo.easeOut' })
    gsap.to(textRefTwo.current, { duration: 0.4, alpha: 1, y: -height, ease: 'Expo.easeOut' })
  }

  const mouseOut = () => {
    if (disabled) {
      return
    }

    gsap.killTweensOf(bgRef.current)
    gsap.killTweensOf(textRefOne.current)
    gsap.killTweensOf(textRefTwo.current)
    gsap.to(bgRef.current, 0.4, { scaleX: 1, scaleY: 1, ease: 'Expo.easeOut' })
    gsap.to(textRefOne.current, 0.5, { y: 0, alpha: 1, ease: 'Expo.easeOut' })
    gsap.to(textRefTwo.current, { duration: 0.4, alpha: 0, y: -aniOffset * 2, ease: 'Expo.easeOut' })
  }

  const clickker = (e) => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <div
      onClick={clickker}
      className="position-relative pointer noselect mt-md-0 mt-4 mb-1"
      style={{ width: width }}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      {outline === false ? (
        <button
          ref={bgRef}
          disabled={disabled}
          className="btn  px-5 py-3 mr-3 noselect rounded"
          style={{
            border: 'none',
            background: background,
            width: width,
            height: height,
            ...style,
          }}
        ></button>
      ) : (
        <div
          ref={bgRef}
          className="btn px-5 py-3 mr-3 noselect rounded"
          style={{
            border: '1px solid #FFFFFF',
            width: width,
            height: height,
            ...style,
          }}
        ></div>
      )}
      <div
        className="position-absolute noselect overflow-hidden"
        style={{ top: 0, left: 0, width: width, height: height }}
      >
        <p
          ref={textRefOne}
          className="mb-0 d-flex justify-content-center align-items-center h-100"
          style={{ color: textColor, whiteSpace: 'nowrap' }}
        >
          {label}
        </p>
        <p
          ref={textRefTwo}
          className="mb-0 d-flex justify-content-center align-items-center h-100"
          style={{ color: textColor, whiteSpace: 'nowrap' }}
        >
          {label}
        </p>
      </div>
    </div>
  )
}

export default Btn

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

console.log(' constants > GOOGLE_MAPS_API_KEY = ', GOOGLE_MAPS_API_KEY)

export const COLOR_DANGER = '#dc3545'
export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#4A6FA5'
export const COLOR_NOT_SELECTED = '#bbbbbb'

// export const QUESTION_TYPES: QuestionType[] = ['text', 'checkbox', 'radio', 'photo', 'number', 'slider', 'info']
// Only supported question types should appear in UI

export type PageType = {
  title: string
  to: string
  path: string
  visible: boolean
  gotoPage: (...rest) => string
}

export const FRONT_PAGE: PageType = {
  title: 'Front',
  path: `/`,
  to: `/`,
  visible: true,
  gotoPage: (projectId) => {
    return '/'
  },
}

export const SITES_PAGE: PageType = {
  title: 'Sites',
  path: `/sites`,
  to: `/sites`,
  visible: true,
  gotoPage: (projectId) => {
    return '/'
  },
}

export const PROJECT_PAGE: PageType = {
  title: 'Project Overview',
  path: `/project/:projectId`,
  to: `/project/:projectId`,
  visible: true,
  gotoPage: (projectId) => {
    return '/project/' + projectId
  },
}

export const STATS_PAGE: PageType = {
  title: 'Stats',
  path: `/stats/:projectId`,
  to: `/stats/:projectId`,
  visible: true,
  gotoPage: (projectId) => {
    return '/stats/' + projectId
  },
}

export const ASSIGNMENT_PAGE: PageType = {
  title: 'Assignment',
  path: `/assignment/:projectId`,
  to: `/assignment`,
  visible: true,
  gotoPage: (projectId) => {
    return '/assignment/' + projectId
  },
}

export const LOGIN = { title: 'Login', to: `/`, base: '/', visible: false }
export const LOGIN_NEW_PASSWORD = {
  title: 'New-password',
  path: `/new-password`,
  to: '/login/new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD_SUBMIT = {
  title: 'submit-new-password',
  to: `/login/submit-new-password`,
  path: '/submit-new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD = {
  title: 'forgot password',
  path: 'forgot-password',
  to: `/login/forgot-password`,
  visible: false,
}

export const LOGIN_MFA_CODE = { title: 'mfa', path: '/mfa', to: `/login/mfa`, visible: false }

export const PAGES = [FRONT_PAGE, ASSIGNMENT_PAGE]
export const SUB_PAGES = [PROJECT_PAGE, STATS_PAGE, ASSIGNMENT_PAGE]

import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'
//import { errorStore } from '../store/ErrorStore'

export const useMoveEnrollment = (enrollmentId: string) => {
  const p = BASE_URL + `/dashboard/subject/site-alternatives/` + enrollmentId

  const { data, error, mutate } = useSWR(p, getReq, { suspense: false })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/04/2022.
 */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LOGIN, LOGIN_FORGOT_PASSWORD_SUBMIT, LOGIN_MFA_CODE } from '../../constants'
import { authStore } from '../../store/auth/authStore'

export const ForgotPassword = () => {
  const [userResponse, setUserReponse] = useState<null | any>(null)
  const [email, setEmail] = useState('')

  const forgotPassword = authStore((state) => state.forgotPassword)
  const [error, setError] = useState('')

  // TODO - send username in state when navigating to the route where you should input code and new password

  const handleFormSubmit = (event) => {
    event.preventDefault()

    //
    // simple validation - to see if email is defined
    //
    if (!email) {
      setError('Please input you username')
    }

    forgotPassword(email)
      .then((res: any) => {
        console.log(' ForgotPassword > res = ', res)
        if (res.CodeDeliveryDetails) {
          setUserReponse(res.CodeDeliveryDetails)
        }
      })
      .catch((error) => {
        console.log(' LoginForm > error = ', error)
        setError(error.message)
      })
  }

  return (
    <div className="bg-white p-4 shadow w-100" style={{ maxWidth: 375 }}>
      <h4 className="font-weight-bold text-center">Reset your password</h4>
      <form className="login-page w-100" onSubmit={handleFormSubmit}>
        <div className="w-100 ">
          <label htmlFor="username">E-mail</label>
          <input
            id="username"
            type="text"
            placeholder="your email"
            autoComplete="username"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        {userResponse ? (
          <div className=" mb-1">
            <p className="text-center mb-3">
              We sent an {userResponse.DeliveryMedium} to: {userResponse.Destination}
            </p>

            <Link to={LOGIN_FORGOT_PASSWORD_SUBMIT.to} className="mt-2">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
              >
                <p className="mb-0">Click to generate new password</p>
              </div>
            </Link>
          </div>
        ) : null}

        {!userResponse ? (
          <div>
            <div className=" ">
              <input
                style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
                type="submit"
                value="Request new password"
              />
              <p className="mt-0 text-center text-danger">{error}</p>
            </div>

            <div className="d-flex justify-content-center ">
              <Link to={LOGIN.to}>
                <p className="p-small">Login</p>
              </Link>
              <Link to={LOGIN_MFA_CODE.to} className="ms-2">
                <p className="p-small">Request code</p>
              </Link>
            </div>
          </div>
        ) : null}
      </form>
    </div>
  )
}

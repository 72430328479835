import { getReq } from '../service/API'
import useSWR from 'swr'
import { getAssignmentsByProjectIdEndpoint } from '../service/api-gateway'
//import { errorStore } from '../store/ErrorStore'

export const useAssignmentsByProjectId = (projectId: string) => {
  const p = getAssignmentsByProjectIdEndpoint(projectId)

  //const cacheKey = formId ? p : null
  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  /*if (error) {
		setError(error.error)
	}*/

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
